dmx.Component('google-distance-matrix', {
  
  initialData: {
    status: '',
    results: [],
  },

  attributes: {
    origins: {
      type: [Array, String],
      default: null,
    },

    destinations: {
      type: [Array, String],
      default: null,
    },

    travelMode: {
      type: String,
      default: 'DRIVING',
      enum: ['DRIVING', 'TRANSIT', 'WALKING', 'BICYCLING'],
    },

    units: {
      type: String,
      default: 'METRIC',
      enum: ['METRIC', 'IMPERIAL'],
    },

    avoidHighways: {
      type: Boolean,
      default: false,
    },

    avoidTolls: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getDistance (origin, destination) {
      this._getDistance(origin, destination);
    },
  },

  render (node) {
    this._service = new google.maps.DistanceMatrixService();
    
    if (this.props.origins && this.props.destinations) {
      this._getDistance(this.props.origins, this.props.destinations);
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('origins') || updatedProps.has('destinations')) {
      if (this.props.origins && this.props.destinations) {
        this._getDistance(this.props.origins, this.props.destinations);
      }
    }
  },

  _getDistance (origin, destination) {
    if (typeof origin === 'string') {
      origin = origin.split(/\s*\|\/*/);
    }

    if (typeof destination === 'string') {
      destination = destination.split(/\s*\|\/*/);
    }

    this._service.getDistanceMatrix({
      origins: origin,
      destinations: destination,
      travelMode: google.maps.TravelMode[this.props.travelMode],
      unitSystem: google.maps.UnitSystem[this.props.units],
      avoidHighways: this.props.avoidHighways,
      avoidTolls: this.props.avoidTolls,
    }, this._result.bind(this));
  },

  _result (response, status) {
    this.set('status', status);

    if (status === 'OK') {
      const results = [];
      const origins = response.originAddresses;
      const destinations = response.destinationAddresses;

      for (let i = 0; i < origins.length; i++) {
        const elements = response.rows[i].elements;

        for (let j = 0; j < elements.length; j++) {
          const element = elements[j];

          results.push({
            origin: origins[i],
            destination: destinations[j],
            distance: element.distance.text,
            duration: element.duration.text,
            meters: element.distance.value,
            seconds: element.duration.value,
          });
        }
      }

      this.set('results', results);
    }
  },

});
