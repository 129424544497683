dmx.Component('google-directions', {

  initialData: {
    routeIndex: 0,
    routes: [],
    status: '',
  },

  attributes: {
    map: { // required
      type: String,
      default: null,
    },

    origin: { // required
      type: [Object, String],
      default: null,
    },

    destination: { // required
      type: [Object, String],
      default: null,
    },

    waypoints: {
      type: [Array, String], // array or | separated string
      default: null,
    },

    travelMode: {
      type: String,
      default: 'DRIVING',
      enum: ['DRIVING', 'WALKING', 'BICYCLING', 'TRANSIT'],
    },

    unitSystem: {
      type: String,
      default: 'METRIC',
      enum: ['METRIC', 'IMPERIAL'],
    },

    departureTime: {
      type: String, // datetime string
      default: null,
    },

    arrivalTime: {
      type: String, // datetime string
      default: null,
    },

    transitModes: {
      type: Array,
      default: null, // BUS, RAIL, SUBWAY, TRAIN, TRAM
    },

    transitPreference: {
      type: String,
      default: null,
      enum: ['FEWER_TRANSFERS', 'LESS_WALKING'],
    },

    showDirections: {
      type: Boolean,
      default: false,
    },

    provideAlternatives: {
      type: Boolean,
      default: false,
    },

    avoidFerries: {
      type: Boolean,
      default: false,
    },

    avoidHighways: {
      type: Boolean,
      default: false,
    },

    avoidTolls: {
      type: Boolean,
      default: false,
    },

    optimizeWaypoints: {
      type: Boolean,
      default: false,
    },

    draggable: {
      type: Boolean,
      default: false,
    },

    dynamicUpdate: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    route () {
      this._route();
    },

    setRouteIndex (index) {
      if (this._directions) {
        this._renderer.setRouteIndex(index);
        this.set('routeIndex', index);
      }
    },
  },

  events: {
    directionschanged: Event,
    routeindexchanged: Event,
    noresults: Event,
    success: Event,
    error: Event,
  },

  init () {
    this._service = new google.maps.DirectionsService();
    this._renderer = new google.maps.DirectionsRenderer({
      draggable: this.props.draggable,
    });

    this._renderer.addListener('directions_changed', this._directionsHandler.bind(this));
    this._renderer.addListener('routeindex_changed', this._routeIndexHandler.bind(this));

    requestAnimationFrame(() => {
      this._route();
    });
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('draggable')) {
      this._renderer.setOptions({ draggable: this.props.draggable });
    }

    if (this.props.dynamicUpdate) {
      if (updatedProps.has('origin') || updatedProps.has('destination') || updatedProps.has('waypoints')) {
        this._route();
      }
    }
  },

  destroy () {
    // cleanup
  },

  _getMap () {
    if (this.props.map) {
      const node = document.getElementById(this.props.map);
      return node && node.dmxComponent && node.dmxComponent._map;
    }
  },

  _route () {
    if (!this.props.origin || !this.props.destination) return;

    const origin = this.props.origin;
    const destination = this.props.destination;

    if (Array.isArray(origin)) {
      origin = origin[0];
    }

    if (Array.isArray(destination)) {
      destination = destination[0];
    }

    if (origin.latitude && origin.longitude) {
      origin = { lat: +origin.latitude, lng: +origin.longitude };
    } else if (origin.lat && origin.lng) {
      origin = { lat: +origin.lat, lng: +origin.lng };
    }

    if (destination.latitude && destination.longitude) {
      destination = { lat: +destination.latitude, lng: +destination.longitude };
    } else if (destination.lat && destination.lng) {
      destination = { lat: +destination.lat, lng: +destination.lng };
    }

    const request = {
      origin: origin,
      destination: destination,
      waypoints: this._getWaypoints(),
      optimizeWaypoints: this.props.optimizeWaypoints,
      travelMode: google.maps.TravelMode[this.props.travelMode],
      unitSystem: google.maps.UnitSystem[this.props.unitSystem],
      provideRouteAlternatives: this.props.provideAlternatives,
      avoidFerries: this.props.avoidFerries,
      avoidHighways: this.props.avoidHighways,
      avoidTolls: this.props.avoidTolls,
    };

    if (this.props.travelMode == 'TRANSIT') {
      request.transitOptions = {};

      if (this.props.arrivalTime) {
        request.transitOptions.arrivalTime = new Date(this.props.arrivalTime);
      }

      if (this.props.departureTime) {
        request.transitOptions.departureTime = new Date(this.props.departureTime);
      }

      if (Array.isArray(this.props.transitModes)) {
        request.transitOptions.modes = this.props.transitModes;
      }

      if (this.props.transitPreference) {
        request.transitOptions.routingPreference = this.props.transitPreference;
      }
    }

    if (this.props.travelMode == 'DRIVING' && this.props.departureTime) {
      request.drivingOptions = {
        departureTime: new Date(this.props.departureTime),
      };
    }

    this._service.route(request, this._updateRoute.bind(this)).catch(error => {
      // do nothing, just preventing uncought error
    });
  },

  _getWaypoints () {
    if (typeof this.props.waypoints == 'string') {
      this.props.waypoints = this.props.waypoints.split(/\s*\|\s*/);
    }

    if (Array.isArray(this.props.waypoints)) {
      return this.props.waypoints.map(waypoint => {
        if (waypoint.lat) waypoint.lat = +waypoint.lat;
        if (waypoint.lng) waypoint.lng = +waypoint.lng;
        return { location: waypoint };
      });
    }

    return null;
  },

  _updateRoute (directions, status) {
    this.set('routeIndex', 0);
    this.set('status', status);

    if (status == 'OK') {
      this._directions = directions;
      
      const map = this._getMap();

      this._renderer.setMap(map || null);
      this._renderer.setDirections(directions);

      if (this.props.showDirections) {
        this._renderer.setPanel(this.$node);
      }

      requestAnimationFrame(() => {
        this.dispatchEvent('success');
      });
    } else {
      //console.warn(directions, status);
      this._directions = null;

      this.set('routes', []);

      if (status == 'ZERO_RESULTS') {
        requestAnimationFrame(() => {
          this.dispatchEvent('noresults');
        });
      } else {
        requestAnimationFrame(() => {
          this.dispatchEvent('error');
        });
      }
    }
  },

  _directionsHandler () {
    const directions = this._renderer.getDirections();

    this.set('routes', directions.routes.map((route, index) => {
      return {
        index: index,
        copyrights: route.copyrights,
        summary: route.summary,
        fare: route.fare ? {
          currency: route.fare.currency,
          value: route.fare.value,
        } : null,
        totalMeters: route.legs.reduce((d, leg) => {
          d += (leg.distance && leg.distance.value || 0);
          return d;
        }, 0),
        totalSeconds: route.legs.reduce((d, leg) => {
          d += (leg.duration && leg.duration.value || 0);
          return d;
        }, 0),
        waypointsOrder: route.waypoint_order,
        legs: route.legs.map(leg => {
          return {
            arrivalDate: leg.arrival_time && leg.arrival_time.value.toISOString(),
            departureDate: leg.departure_time && leg.departure_time.value.toISOString(),
            arrival: leg.arrival_time && leg.arrival_time.text,
            departure: leg.departure_time && leg.departure_time.text,
            distance: leg.distance.text,
            duration: leg.duration.text,
            meters: leg.distance.value,
            seconds: leg.duration.value,
            start: leg.start_address,
            end: leg.end_address,
            steps: leg.steps.map(step => {
              const transit = {};

              if (step.transit) {
                transit.arrival = {
                  stop: step.transit.arrival_stop.name,
                  date: step.transit.arrival_time.value.toISOString(),
                  time: step.transit.arrival_time.text,
                };
                transit.departure = {
                  stop: step.transit.departure_stop.name,
                  date: step.transit.departure_time.value.toISOString(),
                  time: step.transit.departure_time.text,
                };
                transit.headsign = step.transit.headsign;
                transit.numStops = step.transit.num_stops;
                transit.line = step.transit.line;
              }

              return {
                distance: step.distance.text,
                duration: step.duration.text,
                instructions: step.instructions,
                maneuver: step.maneuver,
                travelMode: step.travel_mode,
                transit: transit,
              };
            })
          };
        })
      }
    }));

    setTimeout(() => {
      this.dispatchEvent('directionschanged');
    }, 100);
  },

  _routeIndexHandler () {
    this.set('routeIndex', this._renderer.getRouteIndex());
    requestAnimationFrame(() => {
      this.dispatchEvent('routeindexchanged');
    });
  },

});
